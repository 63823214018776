import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { history, store } from '../helpers';
import { alertActions } from '../actions';
import { PrivateRoute } from '../components';
import {
    QueryComponent, QueryDetailComponent, QueryListComponent, LoginComponent, ImportListingComponent, ImportFileComponent,
    ResetPasswordComponent, SuppressionListingComponent, SuppressionFileComponent, UserListingComponent, ManageUserComponent,
    CohortUploadListingComponent, CohortUploadFileComponent, PreRunListingComponent, AudittrailListingComponent,
    TableManagementListingComponent, TableManagementDetailComponent, DownloadHistoryListingComponent, ManageSplashScreenComponent,
    FlaggedFileDownloadHistoryListingComponent, ChangePasswordComponent, ManageRoleComponent, RoleComponent, SalesPersonListingComponent,
    DataSearchComponent, ManageSalesPersonComponent, ManageExternalUserComponent, LicensableFiltersComponent, SignUpComponent,
    RegistrationConfirmationComponent, EmailConfirmationComponent, DSPDistributionListingComponent, DSPActivationComponent,
    DownloadFileComponent, CampaignOptimizationComponent, MyProfileComponent, ManageCampaignComponent, MyCampaignComponent,
    ManageCustomerComponent, DashboardComponent, UploadDataComponent, SchedulerJobsComponent, IdentityPartnerOnboardingListingComponent,
    DeepIntentListingComponent, DSPImportHistoryListingComponent, DSPFlagMappingComponent, accessdeniedComponent, PageNotFoundComponent,
    ZerobounceListingComponent,CriticalImpactLoglistingComponent
} from "./lazyLoaderComponentList";


const App = () => {
    const [isMenuOpen, setisMenuOpen] = useState(false);
    var dispatch = useDispatch();

    useEffect(() => {
        console.warn = () => { };
        history.listen((location) => {
            dispatch(alertActions.clear());
        });
    }, [dispatch]);


    const toggleMenu = () => {
        setisMenuOpen(!isMenuOpen);
    }

    return (
        <Provider store={store}>
            <main className="main-content">
                {/*  <ToastContainer transition="slide" />*/}
                
                <Router history={history}>
                    <Switch>
                        <Route path="/health"><h3>Hey There!!! The App is Healthy</h3></Route>
                        <PrivateRoute exact path="/" component={QueryListComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/login" component={LoginComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/resetpassword" component={ResetPasswordComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/importlisting" permission="View" parentURL='/importlisting' component={ImportListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/Import" component={ImportFileComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/suppressionlisting" component={SuppressionListingComponent} permission="View" parentURL='/suppressionlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/Suppression" component={SuppressionFileComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/querylisting" component={QueryListComponent} permission="View" parentURL='/querylisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/querydetail" component={QueryDetailComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/Query" component={QueryComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/userlisting" component={UserListingComponent} permission="View" parentURL='/userlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/users" component={ManageUserComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/externalusers" component={ManageExternalUserComponent} permission="Create/Update" parentURL='/userlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/cohortuploadlisting" component={CohortUploadListingComponent} permission="View" parentURL='/cohortuploadlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/cohortuploadfile" component={CohortUploadFileComponent} permission="Create/Update" parentURL='/cohortuploadlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/prerunquerylisting" component={PreRunListingComponent} permission="View" parentURL='/prerunquerylisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/audittraillisting" component={AudittrailListingComponent} permission="View" parentURL='/audittraillisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/licensablefilters" component={LicensableFiltersComponent} permission="View" parentURL='/licensablefilters' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/tablemanagementlisting" component={TableManagementListingComponent} permission="View" parentURL='/tablemanagementlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/tableManagementDetail" component={TableManagementDetailComponent} permission="Create/Update" parentURL='/tablemanagementlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/downloadhistorylisting" component={DownloadHistoryListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/splashscreen" component={ManageSplashScreenComponent} permission="View" parentURL='/splashscreen' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/flaggedfiledownloadhistorylisting" component={FlaggedFileDownloadHistoryListingComponent} permission="View" parentURL='/flaggedfiledownloadhistorylisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/changepassword" component={ChangePasswordComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/rolepermissions" permission="Create/Update" parentURL='/role' component={ManageRoleComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/role" component={RoleComponent} permission="View" parentURL='/role' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/salespersonlisting" component={SalesPersonListingComponent} permission="View" parentURL='/salespersonlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/DataSearch" component={DataSearchComponent} permission="View" parentURL='/DataSearch' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/salesperson" component={ManageSalesPersonComponent} permission="Create/Update" parentURL='/salespersonlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/accessdenied" component={accessdeniedComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/myprofile" component={MyProfileComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/signup" component={SignUpComponent} />
                        <PrivateRoute path="/registrationconfirmation" component={RegistrationConfirmationComponent} />
                        <PrivateRoute path="/signupuseremailconfirmation" component={EmailConfirmationComponent} />
                        <PrivateRoute path="/dspdistributionlisting" component={DSPDistributionListingComponent} permission="View" parentURL='/dspdistributionlisting' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/dspactivation" component={DSPActivationComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/download" component={DownloadFileComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/campaignOptimization" component={CampaignOptimizationComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/manageCampaign" component={ManageCampaignComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/myCampaigns" component={MyCampaignComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/manageCustomer" component={ManageCustomerComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/dashboard" component={DashboardComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/uploadData" component={UploadDataComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/schedulerJobs" component={SchedulerJobsComponent} permission="View" parentURL='/schedulerJobs' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/identityPartnerOnboarding" component={IdentityPartnerOnboardingListingComponent} permission="" parentURL='' toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/deepintentlisting" permission="View" parentURL='/deepintentlisting' component={DeepIntentListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/dspimporthistory" permission="View" parentURL='/dspimporthistory' component={DSPImportHistoryListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/dspmanagement" component={DSPFlagMappingComponent} parentURL='/dspimporthistory' permission="Create/Update" toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/clinicalbehavioraldelivery" permission="View" parentURL='/clinicalbehavioraldelivery' component={DeepIntentListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/zerobouncelisting" permission="View" parentURL='/zerobouncelisting' component={ZerobounceListingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <PrivateRoute path="/criticalImpactLoglisting" permission="View" parentURL='/criticalImpactLogListing' component={CriticalImpactLoglistingComponent} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
                        <Route component={PageNotFoundComponent} updatedstate={true} />
                    </Switch>
                </Router>

            </main>

        </Provider>

    );
}
export default App;