import { salesPersonListingConstants } from '../constants/salespersonlisting.constants';

const initialState = {
    salesPersonListingRequest: {},
    salesPersonListingResponse: {
      salesPersonListingResponse: [],
      totalRecords: 0
    },
    error: '',
    success: '',
    showSalesPersonListingLoader: false
  };

  export function salesPersonListing(state = initialState, action) {
    switch (action.type) {
      case salesPersonListingConstants.GETALLSALESPERSONLISTING_REQUEST:
        return {
          ...state,
          showSalesPersonListingLoader: action.showSalesPersonListingLoader
        };
      case salesPersonListingConstants.GETALLSALESPERSONLISTING_REQUEST_END:
        return {
          ...state,
          showSalesPersonListingLoader: action.showSalesPersonListingLoader
        };
      case salesPersonListingConstants.GETALLSALESPERSONLISTING_SUCCESS:
        return {
          ...state,
          salesPersonListingResponse: action.salesPersonListingResponse
  
        };
      case salesPersonListingConstants.GETALLSALESPERSONLISTING_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case salesPersonListingConstants.CLEAR_ALL_MESSAGE:
        return {
          ...state,
          error: '',
          success: ''
        };
      default:
        return state
    }
  }