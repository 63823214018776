import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './helpers';
import App from './App/App';

import './assets/scss/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle';

// setup fake backend
//import { configureFakeBackend } from './helpers';
//configureFakeBackend();
ReactDOM.render(

    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);