import { flaggedFileDownloadHistoryListingConstants } from '../constants/flaggedfiledownloadhistorylisting.constants';

const initialState = {
    flaggedFileDownloadHistoryListingRequest: {},
    flaggedFileDownloadHistoryListingResponse: {
        flaggedFileDownloadHistoryListingResponse: [],
      totalRecords: 0
    },
    error: '',
    success: '',
    showFlaggedFileDownloadHistoryListingLoader: false
  };

  export function flaggedFileDownloadHistoryListing(state = initialState, action) {
    switch (action.type) {
      case flaggedFileDownloadHistoryListingConstants.GETALLFLAGGEDFILEDOWNLOADHISTORYLISTING_REQUEST:
        return {
          ...state,
          showFlaggedFileDownloadHistoryListingLoader: action.showFlaggedFileDownloadHistoryListingLoader
        };
      case flaggedFileDownloadHistoryListingConstants.GETALLFLAGGEDFILEDOWNLOADHISTORYLISTING_REQUEST_END:
        return {
          ...state,
          showFlaggedFileDownloadHistoryListingLoader: action.showFlaggedFileDownloadHistoryListingLoader
        };
      case flaggedFileDownloadHistoryListingConstants.GETALLFLAGGEDFILEDOWNLOADHISTORYLISTING_SUCCESS:
        return {
          ...state,
          flaggedFileDownloadHistoryListingResponse: action.flaggedFileDownloadHistoryListingResponse
  
        };
      case flaggedFileDownloadHistoryListingConstants.GETALLFLAGGEDFILEDOWNLOADHISTORYLISTING_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case flaggedFileDownloadHistoryListingConstants.CLEAR_ALL_MESSAGE:
        return {
          ...state,
          error: '',
          success: ''
        };
      default:
        return state
    }
  }