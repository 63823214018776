import { postData, getData } from '../helpers/api.helper';

var apiEndPoint = process.env.REACT_APP_API_URL;

export const userService = {
 
    verifyResetpasswordLink,
    updateNewPassword,
    DeleteUsers,
    AddEditUser,
    GetUserById,
    Logout,
    ChangePassword,
    GetAllExternalUsers
};

export function forgotPassword(email, clientAppUrl) {

    var url = apiEndPoint + "Identity/SendForgotUsernameEmail";
    var forgotUsernameRequest = { email: email, clientAppUrl:clientAppUrl };
    return postData(url, forgotUsernameRequest, 'forgotPassword');
}
async function verifyResetpasswordLink(encyptLink) {

    var url = apiEndPoint + "Identity/VerifyResetpasswordLink";
    var updatePasswordRequest = { EncyptLink: encyptLink, Email: "", newPassword: "" };
    return await postData(url, updatePasswordRequest, 'verifyResetpasswordLink');
}

function updateNewPassword(model) {
    var url = apiEndPoint + "Identity/UpdateNewPassword";
    return postData(url, model, 'updateNewPassword');
}

function DeleteUsers(model) {
    let url = apiEndPoint + "UserManagement/DeleteUsers";
    return postData(url, model, 'DeleteUsers');
}

function AddEditUser(model) {
    let url = apiEndPoint + "UserManagement/AddEditUser";
    return postData(url, model, 'AddEditUser');
}

function GetUserById(model) {
    let url = apiEndPoint + "UserManagement/GetUserById";
    return postData(url, model, 'GetUserById');
}

function ChangePassword(model) {
    let url = apiEndPoint + "UserManagement/ChangePassword";
    return postData(url, model, 'ChangePassword');
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 //   location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }

function Logout(model) {    
    var url = apiEndPoint + "Identity/Logout";
    return postData(url, model, 'Logout');
}

function GetAllExternalUsers() {
    var url = apiEndPoint + "UserManagement/GetAllExternalUsers";
    return getData(url, '', 'GetAllExternalUsers');
}


//export async function testFileUpload(files, onUploadProgress) {
//    var authenticateRequestModel = {
//        UserName: 'sarpatel@synoptek.com',
//        Password: 'Test@12345',
//        Type: '',
//        IpAddress: ''
//    };
//    var url = fileapiEndPoint;
//    return await axios.post(url, files, {
//        headers: { 'Content-Type': 'multipart/form-data' },
//        onUploadProgress
//    });
  
//}