import moment from 'moment';
var CryptoJS = require("crypto-js");

export function formatDateMMDDYY(date) {

    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }

    return month + '/' + day + '/' + date.getFullYear();
}

export function formatDateMMDDYYYYHHMMSSAMPM(date) {
    if (date) {
        return moment.utc(date).local().format("MM/DD/YYYY  hh:mm:ss A");
    }
    return ''
}

export function formatDateHHMMAMPM(date) {
    if (date) {
        return moment.utc(date).local().format("hh:mm A");
    }
    return ''
}

export function formatDateMMDDYYYY(date) {
    if (date) {
        return moment.utc(date).local().format("MM/DD/YYYY");
    }
    return ''
}
export function formatDateTimeMMDDYYYYHHMMSSAMPM(date) {
    if (isDate(date)) {
        return moment(date).format("MM/DD/YYYY  hh:mm:ss A");
    }
    return ''
}

export function getTimeDifferenceString(startDateTime, endDateTime) {
    if (startDateTime && endDateTime) {

        let startTime = moment(startDateTime);
        let endTime = moment(endDateTime);
        let duration = moment.duration(endTime.diff(startTime));
        if (duration) {
            let days = duration._data.days
            let hours = duration._data.hours
            let minutes = duration._data.minutes
            let seconds = duration._data.seconds

            let strText = ''
            if (days > 0) {
                strText = days + ' day(s)'
            }

            if (hours > 0) {
                strText = strText + ' ' + hours + ' hour(s)'
            }

            if (minutes > 0) {
                strText = strText + ' ' + minutes + ' minute(s)'
            }

            if (seconds > 0) {
                strText = strText + ' ' + seconds + ' second(s)'
            }

            if (strText.trim().length === 0) {
                strText = '0 second(s)'
            }

            return strText.trim();
        }
        return '-';
    }
    return '-';
}

export function getIndexFromArray(array, item) {

    return array.indexOf(item);
}

export function isDate(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function yesterday() {
    return moment().subtract(1, 'day');
}

export function isDateGreater(Date1, Date2) {
    return moment(Date1).diff(moment(Date2), 'days') >= 0;
}

export const encodeEntities = (str) => {
    const htmlEntities = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&apos;"
    };
    return str && str.replace(/([&<>"'])/g, match => htmlEntities[match]);
}

export const decodeEntities = (html) => {
    var cache = {},
        character,
        e = document.createElement('div');
    if (html !== "" && html !== undefined && html !== null)
        return html.replace(/([&][^&; ]+[;])/g, function (entity) {
            character = cache[entity];
            if (!character) {
                e.innerHTML = entity;
                if (e.childNodes[0])
                    character = cache[entity] = e.childNodes[0].nodeValue;
                else
                    character = '';
            }
            return character;
        });
}

export function getGreaterOrLessSignBasedOnBeforeAfter(stringBeforeAfter) {
    return stringBeforeAfter === "Before" ? '<' : '>';
}

export function setYearOptions() {
    let year = new Date().getFullYear();
    let yearOptions = []
    yearOptions.push({ "label": "Select Year", "value": 0 });
    for (let i = year + 5; i > 1899; i--) {
        yearOptions.push({ "label": i, "value": i });
    }

    return { yearOptions: yearOptions, currentYear: year };

}

export function getINorNotInText(stringIsorNotIs) {
    // return stringIsorNotIs === "Is" ? 'IN' : 'NOT IN';
    if (stringIsorNotIs === "Is" || stringIsorNotIs === "Work"
        || stringIsorNotIs === "Specialize" || stringIsorNotIs === "Employee"
        || stringIsorNotIs === "Has" || stringIsorNotIs === "Have" || stringIsorNotIs === "Employ") {
        return 'IN';
    }
    return 'NOT IN';
}

export function getJoinSyntaxBasedOnTableName(tableNameList, leftjoinTable, ignoreTableList) {
    var searchQueryJoinStatement = '';
    tableNameList.forEach((item) => {
        if (ignoreTableList.indexOf(item) < 0) {
            switch (item) {
                case ("Custom.HCPs"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join Custom.HCPs as HCPs on  HCPs.MDGID = " + leftjoinTable + " AND HCPs.IsLicensable = true";
                    break;
                case ("Custom.Specialty"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join Custom.SPECIALTY as SPECIALTY on SPECIALTY.MDGID = " + leftjoinTable + " AND SPECIALTY.IsLicensable = true";
                    break;
                case ("Custom.SpecialtyGroups"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.SpecialtyGroups as SpecialtyGroups on  SpecialtyGroups.MDGID = " + leftjoinTable + " AND SpecialtyGroups.IsLicensable = true";
                    break;
                case ("Custom.License"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.License as License on License.MDGID = " + leftjoinTable + " AND License.IsLicensable = true";
                    break;
                case ("Custom.Organizations"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.Organizations as Organizations on Organizations.MDGORGID = Addresses.MDGORGID ";
                    break;
                case ("Custom.HospitalJobFunction"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.HospitalJobFunction as HospitalJobFunction on HospitalJobFunction.MDGID = " + leftjoinTable + " AND HospitalJobFunction.IsLicensable = true";
                    break;
                case ("Custom.Addresses"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.Addresses as Addresses on  Addresses.MDGID = " + leftjoinTable + " AND Addresses.IsLicensable = true";
                    break;
                case ("Custom.Email"):
                    searchQueryJoinStatement = searchQueryJoinStatement + " left join  Custom.Email as Email on  Email.MDGID = " + leftjoinTable + " AND Email.IsLicensable = true";
                    break;
                 default:
                    break;
                    
            }
        }
    })

    return searchQueryJoinStatement;
}

export function remove_array_element(array, n) {
    var index = array.indexOf(n);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}


export function getUserPagePermission(page, permissionName) {
    var userDetail = {};
    var havePermission = false;

    if (page !== undefined && page !== "") {
        var userData = localStorage.getItem("user");
        if (userData && userData.length > 0) {
            var data = JSON.parse(userData);
            var bytes = CryptoJS.AES.decrypt(data, 'my-secret-key@123');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            userDetail = decryptedData;
            if(userDetail !== undefined && userDetail.roleWisePermission !== undefined && userDetail.roleWisePermission.moduleList !== undefined){
                userDetail.roleWisePermission.moduleList.forEach((item, i) => {
                    item.subModuleList.forEach((subModuleItem, j) => {
                        if (subModuleItem.url !== null && subModuleItem.url !== undefined && subModuleItem.url.toLowerCase() === page.toLowerCase()) {
                            if (subModuleItem.permissionList.some(x => x === permissionName)) {
                                havePermission = true;
                            }
                        }
                    });
                });
            }           

            return havePermission;
        }
    } else {
        havePermission = true;
        return havePermission;
    }

}

export function selectText(id) {
    var sel, range;
    var el = document.getElementById(id); //get element id
    if (window.getSelection && document.createRange) { //Browser compatibility
        sel = window.getSelection();
        if (sel.toString() == '') { //no text selection
            window.setTimeout(function () {
                range = document.createRange(); //range object
                range.selectNodeContents(el); //sets Range
                sel.removeAllRanges(); //remove all ranges from selection
                sel.addRange(range);//add Range to a Selection.
            }, 1);
        }
    } else if (document.selection) { //older ie
        sel = document.selection.createRange();
        if (sel.text == '') { //no text selection
            range = document.body.createTextRange();//Creates TextRange object
            range.moveToElementText(el);//sets Range
            range.select(); //make selection.
        }
    }
}


export function getLabelFromArray(sourceArray, propertyName, value, labelPropertyName) {

    var labelName = "";
    var result = sourceArray.filter(x => x[propertyName] == value);
    if (result.length > 0) {
        var firstItem = result[0];
        labelName = firstItem[labelPropertyName];      
    }
    return labelName;
}

