import { createGlobalStyle} from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body{
    background:${({ theme }) => theme.bodybg};
    color:${({ theme }) => theme.text};
  }
  .app-header-wrapper{
    background:${({ theme }) => theme.sidebarbg};
    box-shadow: 0px 3px 0px 0px ${({ theme }) => theme.mdgprimary};
  }
  .side-nav{
    background:${({ theme }) => theme.sidebarbg};
  }
  .p-panelmenu .p-panelmenu-header a, .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text,
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    color:${({ theme }) => theme.sidebartext};
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text{
    color:${({ theme }) => theme.text} !important;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon{
    color:${({ theme }) => theme.text} !important;
  }
  .datatable-page.p-datatable {
    .p-datatable-wrapper{
      border-top:2px solid ${({ theme }) => theme.mdgprimary};
    }
    .p-datatable-header{
      background:${({ theme }) => theme.mainbg} !important;
    }
    thead {
      tr {
        th{
          color:${({ theme }) => theme.textgrid} !important;
          background:${({ theme }) => theme.mainbg} !important;
        }
      }
    }
    .p-datatable-thead {
      tr{
        th{
          border-color:${({ theme }) => theme.ascentborder} !important;
        }
      }
    }
    .p-datatable-tbody  {
      tr{
        background:${({ theme }) => theme.mainbg} !important;
        &.p-highlight{
          background:${({ theme }) => theme.rowhighlight} !important;
        }
        td{
          color:${({ theme }) => theme.textgrid} !important;
          border-color:${({ theme }) => theme.ascentborder} !important;
        }
      }
    }
  }
  .p-multiselect .p-multiselect-trigger,
  .p-multiselect .p-multiselect-clear-icon{
    color:${({ theme }) => theme.iconfontcolor};
  }

  .p-paginator{
    background:${({ theme }) => theme.mainbg} !important;
    .p-paginator-current, .p-paginator-prev, .p-paginator-first, .p-paginator-next, .p-paginator-last{
      color:${({ theme }) => theme.iconfontcolor};
    }
    .p-dropdown .p-dropdown-label{
      color:${({ theme }) => theme.iconfontcolor};
    }
    .p-paginator-pages {
      .p-paginator-page{
        &.p-highlight{
          background: ${({ theme }) => theme.mdgprimary};
          border-color: ${({ theme }) => theme.mdgprimary};
        }
      }
    }
  }
  
  .customer-badge{
    color:${({ theme }) => theme.textgrid} !important;
  }
  .page-title h1{
    color:${({ theme }) => theme.iconfontcolor};
  }
  .box-title{
    background-color:${({ theme }) => theme.boxheader};
    color:${({ theme }) => theme.iconfontcolor};
  }
  .white-box{
    background-color:${({ theme }) => theme.mainbg};
  }
  .ah-boxshadow{
    background-color:${({ theme }) => theme.sidebarbg};
    .ah-box-content{
      ul{
        li{
          a{
            color:${({ theme }) => theme.iconfontcolor};
          }
        }
      }
    }
  }
  .ah-tabs {
    .p-tabview {
      .p-tabview-nav {
        li {
          .p-tabview-nav-link{
            background:${({ theme }) => theme.mdgprimary};
          }
          &.p-highlight {
            .p-tabview-nav-link{
              background:${({ theme }) => theme.mdgprimary};
            }
            &:before{
              color:${({ theme }) => theme.mdgprimary};
            }
          }
        }
      }
    }
  }
  .ah-tabs2{
    .p-tabview {
      .p-tabview-nav{
        background:${({ theme }) => theme.sidebarbg};
        li {
          .p-tabview-nav-link{
            color:${({ theme }) => theme.iconfontcolor};
          }
          &.p-highlight {
            .p-tabview-nav-link{
              background-color:${({ theme }) => theme.tabhighlight};
              color:${({ theme }) => theme.iconfontcolor};
            }
          }
        }
      }
      .p-tabview-panels{
        color:${({ theme }) => theme.labeltext};
      }
    }
  }
  .p-inputtext{
    color:${({ theme }) => theme.labeltext};
  }
  .view-icon{
    color:${({ theme }) => theme.iconfontcolor};
  }
  label, span, p{
    color:${({ theme }) => theme.labeltext};
  }
  .gray-bg{
    background:${({ theme }) => theme.box2};
    border:1px solid ${({ theme }) => theme.box2};
    color:${({ theme }) => theme.iconfontcolor};
  }
  .upload-table{
    table{
      tr{
        td{
          color:${({ theme }) => theme.iconfontcolor};
        }
      }
    }
  }
  .side-nav.collapsed .p-panelmenu .p-toggleable-content{
    background-color:${({ theme }) => theme.sidebarbg};
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background-color:${({ theme }) => theme.bodybg};
  }
  .p-accordion .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    border-color:${({ theme }) => theme.mdgprimary};
  }
  .p-accordion-header-text,
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    color:${({ theme }) => theme.iconfontcolor} !important;
  }
  .filter-part {
    .filter-part-title{
      background:${({ theme }) => theme.secondarycolor};
      color:${({ theme }) => theme.iconfontcolor} !important;
    }
    .fa-copy{
      color:${({ theme }) => theme.formtext} !important;
    }
  }
  .field-data-box{
    background:${({ theme }) => theme.mainbg};
  }
  .field-sublist li{
    color:${({ theme }) => theme.iconfontcolor};
  }
  .field-sublist li{
    &:hover{
      background:${({ theme }) => theme.ascentgray};
      color:${({ theme }) => theme.bodyfont};
    }
  }
  .field-alphabet-letter a{
    color:${({ theme }) => theme.iconfontcolor};
  }
  .p-fileupload .p-fileupload-content{
    background-color:${({ theme }) => theme.mainbg};
    color:${({ theme }) => theme.iconfontcolor};
  }
  .link-color{
    color:${({ theme }) => theme.mdgprimary};
  }
  .header-right-title{
    color:${({ theme }) => theme.ascentwg} !important;
  }
  .page-title .page-title-icon{
    color:${({ theme }) => theme.mdgprimary};
  }
  .ah-grid .p-datatable .p-datatable-header{
    border: ${({ theme }) => theme.mdgprimary};
    border-width: 0px 0 2px 0;
  }
  .side-nav{
    .p-panelmenu {
     .p-panelmenu-content{
      .p-menuitem {
       .p-menuitem-link{
        &:not(.p-disabled):hover{
         background: transparent;
         .p-menuitem-text{
           color: ${({ theme }) => theme.mdgprimary};
         }
        }
       }
       .p-menuitem-link{
        &:focus{
         box-shadow: none;
         .p-menuitem-text{
           color: ${({ theme }) => theme.mdgprimary};
         }
        }
        &:hover{
         color: ${({ theme }) => theme.mdgprimary};
        }
       }
      }
     }
     .p-panelmenu-header {
      &:not(.p-disabled) {
       a{
        &:hover{
         color: ${({ theme }) => theme.mdgprimary};
        }
       }
      }
      &:not(.p-highlight):not(.p-disabled) {
       a{
        position: relative;
        &:hover{
         color: ${({ theme }) => theme.mdgprimary};
         &:before{
         box-shadow: 4px 0px 24.65px 4.35px ${({ theme }) => theme.mdgprimary};
         opacity:0.8;
         border-left: 3px solid ${({ theme }) => theme.mdgprimary};
         }
        }
       }
      }
      &.p-highlight{
       .p-panelmenu-header-link{
        color: ${({ theme }) => theme.mdgprimary};
        &:before{
        box-shadow: 4px 0px 24.65px 4.35px ${({ theme }) => theme.mdgprimary};
        opacity:0.8;
        border-left: 3px solid ${({ theme }) => theme.mdgprimary};
        }
       }
      }
     }
   }
  }
  .excel-file-icon, .locked-by-user-icon{
  color: ${({ theme }) => theme.mdgprimary} !important;
  }
  .p-checkbox  {
    .p-checkbox-box{
      color:#fff;
      &.p-highlight{
        border-color: ${({ theme }) => theme.mdgprimary};
        background: ${({ theme }) => theme.mdgprimary};
       }
    }
  }
  .btn-primary{
    background: ${({ theme }) => theme.mdgprimary};
    &:active{
      background: ${({ theme }) => theme.mdgprimary};
      color: ${({ theme }) => theme.ascentww};
    }
    &:focus{
      background: ${({ theme }) => theme.mdgprimary};
      color: ${({ theme }) => theme.ascentww};
    }
   }
   .p-button{
    border-color: ${({ theme }) => theme.mdgprimary};
    background: ${({ theme }) => theme.mdgprimary};
   }
   .ah-fileupload {
    .ah-fileupload-choose{
      border-color: ${({ theme }) => theme.mdgprimary};
    }
   }
   .p-breadcrumb{
    border-color: ${({ theme }) => theme.ascentborder};
   }
   .custom-tooltip-btn{
    i{
      color: ${({ theme }) => theme.mdgprimary};
    }
   }
   .field-search{
    border-bottom-color: ${({ theme }) => theme.mdgprimary};
    .p-input-icon-right {
      i:last-of-type{
        color: ${({ theme }) => theme.mdgprimary};
      }
    }
   }
   .field-data-box{
    border-color: ${({ theme }) => theme.mdgprimary};
   }
   .field-mapping{
     span{
      background: ${({ theme }) => theme.mdgprimary};
      color: #fff !important;
     }
   }
   .record-arrows i {
      color: ${({ theme }) => theme.mdgprimary};
    }
    .p-radiobutton .p-radiobutton-box.p-highlight {
      border-color: ${({ theme }) => theme.mdgprimary};
      background: ${({ theme }) => theme.mdgprimary};
  }
    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
      border-color: ${({ theme }) => theme.mdgprimary};
  }
  .field-alphabet-letter{
    .all-letter{
      color: ${({ theme }) => theme.mdgprimary};
    }
  }
  .text-primary{
    color: ${({ theme }) => theme.mdgprimary} !important;
  }
  .output-table{
    .p-datatable-tbody{
        tr{
            td{
                &:nth-child(6){
                  color: ${({ theme }) => theme.mdgprimary} !important;
                   
                }
            }
        }
    }
}
.section-divide-OR{
  background-color: ${({ theme }) => theme.box2};
  color: ${({ theme }) => theme.formtext};
}
.dropdown-primary{
  background: ${({ theme }) => theme.mdgprimary};
 }
//  .ah-grid{
//   .p-datatable {
//     &.p-datatable-resizable{
//       .p-datatable-thead {
//         tr{
//           th{
//             border:1px solid ${({ theme }) => theme.ascentborder};
//           }
//         }
//         }
//       .p-datatable-tbody {
//         tr {
//           td{
//             border:1px solid ${({ theme }) => theme.ascentborder};
//           }
//         }
//       }
//     }
//   }
// }
//  .app-name h1, .app-name span{
//   color: ${({ theme }) => theme.sidebartext} !important;
//  }
//  .app-name{
//   border-bottom:1px solid ${({ theme }) => theme.ascentborder};
//  }
 .p-treetable {
  .p-column-resizer{
    // border:1px solid ${({ theme }) => theme.ascentborder};
    background: ${({ theme }) => theme.ascentborder};
    width:3px;
    pointer-events:all;
  }
  .p-treetable-header{
    background:${({ theme }) => theme.mainbg} !important;
    color:${({ theme }) => theme.textgrid} !important;
    .searchtext-color{
      color:${({ theme }) => theme.textgrid} !important;
    }
  }
  .p-treetable-thead {
    tr{
      th{
        color:${({ theme }) => theme.textgrid} !important;
        background:${({ theme }) => theme.mainbg} !important
      }
    }
  }
  .p-treetable-tbody{
    tr{
      color:${({ theme }) => theme.textgrid} !important;
      border-color:${({ theme }) => theme.ascentborder} !important;
      background:${({ theme }) => theme.mainbg} !important
    }
  }
  
  &.p-treetable-resizable{
    .p-treetable-thead {
      tr{
        th{
          border-color:${({ theme }) => theme.ascentborder} !important;
        }
      }
      }
    .p-treetable-tbody {
      tr {
        td{
          color: ${({ theme }) => theme.formtext} !important;
          border-color:${({ theme }) => theme.ascentborder} !important;
        }
      }
    }
  }
}
.box-loader .spin-loader-overlay{
  background:${({ theme }) => theme.loaderbg};
}
.fixed-loader .spin-loader-overlay{
  background:${({ theme }) => theme.loaderbg};
}
.p-datatable .p-column-resizer{
  // border:1px solid ${({ theme }) => theme.ascentborder};
  background: ${({ theme }) => theme.ascentborder};
    width:3px;
pointer-events:all;
}
.use-dropdown{
  .dropdown-menu{
    background-color:${({ theme }) => theme.ascentwg};
    .dropdown-item{
      color: ${({ theme }) => theme.formtext};
      &:hover{
        background:transparent;
      }
    }
  }
}
.p-dropdown-panel{
  background:${({ theme }) => theme.mainbg};
  .p-dropdown-header{
    background:${({ theme }) => theme.mainbg};
    color: ${({ theme }) => theme.textgrid};
  }
  .p-dropdown-items {
    .p-dropdown-empty-message{
      color: ${({ theme }) => theme.textgrid};
    }
    .p-dropdown-item{
      color: ${({ theme }) => theme.textgrid};
    }
  }
}
.p-multiselect-panel{
  background:${({ theme }) => theme.mainbg};
  .p-multiselect-header{
    background:${({ theme }) => theme.mainbg};
  }
  .p-multiselect-items {
    .p-multiselect-item{
      color: ${({ theme }) => theme.textgrid};
    }
  }
}
.p-dialog {
  .p-dialog-header{
    background:${({ theme }) => theme.mdgprimary};
  }
  .p-dialog-content{
    background:${({ theme }) => theme.mainbg};
    color: ${({ theme }) => theme.textgrid};
  }
  .p-dialog-footer{
    background:${({ theme }) => theme.mainbg};
  }
  .ah-listbox{
    color: ${({ theme }) => theme.textgrid};
  }
}
.mdg-save-template{
  color: ${({ theme }) => theme.textgrid};
}
.list-hover-icon{
  .dropdown-menu{
    background:${({ theme }) => theme.mainbg};
    .dropdown-item{
      color: ${({ theme }) => theme.textgrid } !important;
      &:hover{
        background:${({ theme }) => theme.mainbg};
      }
      &.active{
        background:${({ theme }) => theme.mainbg};
      }
    }
  }
 }
 .p-datepicker:not(.p-datepicker-inline){
  background:${({ theme }) => theme.mainbg};
  border:1px solid ${({ theme }) => theme.ascentborder};
  .p-datepicker-header{
    background:${({ theme }) => theme.mainbg};
  }
 }
 .p-datepicker{
  color: ${({ theme }) => theme.textgrid};
  .p-datepicker-header{
    border-bottom:1px solid ${({ theme }) => theme.ascentborder};
    color: ${({ theme }) => theme.textgrid};
    .p-datepicker-prev, .p-datepicker-next{
      color: ${({ theme }) => theme.textgrid};
    }
  }
  .p-datepicker-buttonbar{
    border-top:1px solid ${({ theme }) => theme.ascentborder};
    .p-button.btn-primary{
      border-color: ${({ theme }) => theme.mdgprimary};
      background: ${({ theme }) => theme.mdgprimary};
    }
  }
 }
 .action-dropdown{
  .dropdown-menu{
    background: ${({ theme }) => theme.ascentwb};
    .dropdown-item{
      color: ${({ theme }) => theme.textgrid};
      &:hover, &:focus{
        background: ${({ theme }) => theme.mdgprimary};
        color:#fff;
      }
    }
  }
 }
 .map-listbox{
  .p-listbox{
    background:${({ theme }) => theme.mainbg};
    color: ${({ theme }) => theme.textgrid};
    border:1px solid ${({ theme }) => theme.ascentborder};
    .p-listbox-header{
      background:${({ theme }) => theme.mainbg};
      color: ${({ theme }) => theme.textgrid};
      .p-inputtext{
        color: ${({ theme }) => theme.textgrid};
      }
      .p-listbox-filter-icon{
        color: ${({ theme }) => theme.textgrid};
      }
    }
    .p-listbox-list {
      .p-listbox-item{
        color: ${({ theme }) => theme.textgrid};
      }
    }
  }
 }
 .lookupfile-dropdown{
  &.dropdown-menu{
    background: ${({ theme }) => theme.mainbg};
    box-shadow:none;
    .dropdown-item{
      color: ${({ theme }) => theme.textgrid};
      &:hover, &:focus{
        background: ${({ theme }) => theme.mdgprimary};
        color:#fff;
      }
    }
  }
 }
 .sectn-bg{
  background: ${({ theme }) => theme.mainbg};
 }
 .form-control{
    background-color: ${({ theme }) => theme.mainbg};
    color: ${({ theme }) => theme.textgrid};
    border-color:${({ theme }) => theme.inputborder};
 }
 .form-control-border{
  border:1px solid ${({ theme }) => theme.inputborder};
 }
 .p-editor-container {
  .p-editor-toolbar{
    background: ${({ theme }) => theme.bodybg};
    &.ql-snow{
      border:1px solid ${({ theme }) => theme.ascentborder};
      .ql-picker{
        &.ql-expanded {
          .ql-picker-options{
            background: ${({ theme }) => theme.ascentwb};
          }
        }
      }
    }
  }
  .p-editor-content {
    .ql-editor{
      background: ${({ theme }) => theme.mainbg};
      color: ${({ theme }) => theme.textgrid};
    }
    &.ql-snow{
      border:1px solid ${({ theme }) => theme.ascentborder};
    }
  }
 }
 .fa-info-circle{
  color: ${({ theme }) => theme.mdgprimary};
 }
 .modal-table{
  &.p-datatable {
    .p-datatable-tbody {
      tr{
        background: ${({ theme }) => theme.mainbg};
        color: ${({ theme }) => theme.textgrid};
      }
    }
    .p-datatable-thead {
      tr{
        th{
          background: ${({ theme }) => theme.mainbg};
          color: ${({ theme }) => theme.textgrid};
        }
      }
    }
   }
 }
 .text-primary, .p-row-editor-init-icon{
  color: ${({ theme }) => theme.mdgprimary} !important;
 }
 .lookup-icon{
    color: ${({ theme }) => theme.mdgprimary};
  }
  .box-border-top{
    border-top:2px solid ${({ theme }) => theme.mdgprimary};
   }
  .mdg-toggle-btn.p-togglebutton.p-button .p-button-label {
    color: ${({ theme }) => theme.mdgprimary} !important;
  }
  .data-search-content{
    .box-content{
      color: ${({ theme }) => theme.labeltext};
    }
  }
  .p-multiselect{
    .p-multiselect-label{
      &.p-placeholder{
        color: ${({ theme }) => theme.formtext};
      }
    }
  }
  .datasearch-main{
    p, label, span{
      color: ${({ theme }) => theme.labeltext} !important;
    }
    .alert-primary{
      background: ${({ theme }) => theme.ascent2};
      color: ${({ theme }) => theme.labeltext};
      font-weight:bold;
    }
    .box-title{
      border-bottom:2px solid ${({ theme }) => theme.mdgprimary};
      color: ${({ theme }) => theme.ascent4};

    }
  }
  .tile{
    background: ${({ theme }) => theme.sidebarbg};
  }
  .user-name{
    color: ${({ theme }) => theme.text} !important;
  }
  .nav .nav-right ul .nav-right-item .dropdown-toggle{
    color: ${({ theme }) => theme.text} !important;
  }
  .nav .nav-right ul .nav-right-item svg{
    color: ${({ theme }) => theme.mdgprimary} !important;
  }
 
  .data-search-content {
    .box-content{
      background-color:${({ theme }) => theme.mainbg} !important;
    }
  }
  .box-copy{
    background-color:${({ theme }) => theme.mainbg} !important;
  }
  
  .white-box{
    span, label, p{
      color:${({ theme }) => theme.labeltext} !important;
    }
    .box-content{
      span, label{
        color:${({ theme }) => theme.labeltext} !important;
      }
      .form-control:focus{
        color:${({ theme }) => theme.labeltext} !important;
      }
    }
  }
  
  .p-inputswitch{
    &.p-inputswitch-checked {
      .p-inputswitch-slider{
        background: ${({ theme }) => theme.mdgprimary} !important;
      }
    }
  }
  .white-box-title{
    color: ${({ theme }) => theme.labeltext};
  }
  .dropdown_link{
    span.p-dropdown-label{
      color: ${({ theme }) => theme.mdgprimary} !important;
    }
  }
  .form-control:disabled, .form-control[readonly]{
    background-color:${({ theme }) => theme.disabled}; 
  }
  .mdg-bs-radio label > input[type="radio"]:checked + *::before {
    background: ${({ theme }) => theme.mdgprimary};
    border-color: ${({ theme }) => theme.mdgprimary};
   }
   .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
   .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    border-color: ${({ theme }) => theme.mdgprimary} !important;
   }
   .p-multiselect.p-inputwrapper-filled .p-multiselect-label{
    color: ${({ theme }) => theme.formtext};
   }
   .p-datepicker table td span.p-highlight,
   .p-datepicker table td.p-datepicker-today span,
   .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
   .p-datepicker table td.p-datepicker-today span.p-highlight{
    background: ${({ theme }) => theme.mdgprimary};
    color:#fff !important;
   }
   .nav-left .header-left-title.header-menuicon i{
    background: ${({ theme }) => theme.mdgprimary};
   }
   .tile{
     h3{
      color: ${({ theme }) => theme.ascentgrey};
     }
   }
   .dsptable{
    .p-datatable {
      .p-datatable-tbody {
        tr{
          background: ${({ theme }) => theme.ascent3};
          color: ${({ theme }) => theme.labeltext};
        }
      }
     }
   }
   .dist_dsp{
    background: ${({ theme }) => theme.mdgprimary};
    border:1px solid ${({ theme }) => theme.mdgprimary};
    &.p-dropdown{
      .p-dropdown-label{
        color:#fff !important;
      }
      .p-dropdown-trigger{
        display:none;
      }
    }
   }
   .highcharts-container .highcharts-legend text,
   .highcharts-container .highcharts-axis-labels text,
   .highcharts-container .highcharts-axis text{
    color: ${({ theme }) => theme.formtext} !important;
    fill: ${({ theme }) => theme.formtext} !important;
   }
   .mdg-picklist-header{
        background: ${({ theme }) => theme.ascent5};
        color: ${({ theme }) => theme.ascentww};
      }
  
   .select-code-modal{
    &.p-dialog {
      .p-dialog-header{
        background: ${({ theme }) => theme.ascentwg};
        color: ${({ theme }) => theme.labeltext};
        border-bottom:2px solid ${({ theme }) => theme.mdgprimary};
        .p-dialog-header-close-icon{
          color: ${({ theme }) => theme.labeltext} !important;
        }
      }
      .mdg-picklist-searchsort{
        background: ${({ theme }) => theme.ascent10};
        .search-textbar{
          input{
            background: ${({ theme }) => theme.ascent9};
            color: ${({ theme }) => theme.labeltext} !important;
          }
        }
        .sort-icons{
          i{
            color: ${({ theme }) => theme.labeltext};
          }
        }
      }
    }
  }
 
  .custm-picklist-buttons {
    .p-button {
      border-color: ${({ theme }) => theme.ascent7};
      background: ${({ theme }) => theme.ascent7};
      .p-button-icon{
        color: ${({ theme }) => theme.ascent8} !important;
      }
  }
  }
  .ReactTags__selected span.ReactTags__tag{
    background: ${({ theme }) => theme.ascent11};
    color: ${({ theme }) => theme.ascent8} !important;
  }
  .ReactTags__remove {
    color: ${({ theme }) => theme.ascent8};
  }
  .switchplatform-icon{
    border-bottom:1px solid ${({ theme }) => theme.ascentborder};
  }
  .custm-picklist{
    .custm-picklist-box{
      .p-listbox{
        border:solid ${({ theme }) => theme.ascentborder};
        border-width: 1px 0 0 0;
        background: ${({ theme }) => theme.ascent10};
        .p-listbox-header {
          .p-listbox-filter-icon{
            color: ${({ theme }) => theme.labeltext} !important;
          }
        }
        .p-listbox-item{
          color: ${({ theme }) => theme.labeltext};
          border-bottom:1px solid ${({ theme }) => theme.ascentborder};
          .product-item{
            &.disable-list{
              background-color:${({ theme }) => theme.disabled};
              cursor:default;
            }
          }
          &.p-highlight{
            background: ${({ theme }) => theme.ascent11};
            // color: ${({ theme }) => theme.ascentww};
          }
        }
        .p-listbox-header{
          background: ${({ theme }) => theme.ascent10};
          .p-inputtext{
            border: 1px solid ${({ theme }) => theme.ascentborder};
            border-width:0 0 1px 0;
          }
        }
      }
    }
    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover{
      background: ${({ theme }) => theme.ascent11};
    }
  }
  .form-control:focus{
    color:${({ theme }) => theme.labeltext} !important;
  }
  .custm-picklist-box .product-item .product-code {
    color: ${({ theme }) => theme.mdgprimary} !important;
  }
  .custm-picklist-box .product-item .product-category {
    color: ${({ theme }) => theme.labeltext} !important;
  }
  .download-file-icon i{
    border:10px solid ${({ theme }) => theme.ascent12};
  }
  .datatable-page.p-datatable .p-datatable-tbody .row-copy {
    background: ${({ theme }) => theme.ascent13} !important;
}
.datasearch-main{
  .white-box{
    background-color:${({ theme }) => theme.ascentwb};
    border:1px solid ${({ theme }) => theme.ascent3};
  }
}
.file-drag-drop{
  border:1px solid ${({ theme }) => theme.ascentborder};
  .pi-image{
    background-color:${({ theme }) => theme.bodybg};
  }
}
.border-btn{
  border:1px solid ${({ theme }) => theme.ascent14}
}
.data-search-seperate-field{
    background-color:${({ theme }) => theme.bodybg};
}  
.networkLoaderClass{
  display:none;
} `