import { userConstants } from '../constants';


const initialState = {
    userDetail: {},
    error: '',
    success: ''
};

export function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                userDetail: action.users

            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                success: action.message
            };
        case userConstants.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.message
            };
        case userConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        case userConstants.LOGINREQUEST:
            return {
                ...state,
                showLogInLoader: action.showLogInLoader
            };
        case userConstants.LOGINREQUESTEND:
            return {
                ...state,
                showLogInLoader: action.showLogInLoader
            };

        default:
            return state
    }
}