import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAccessToken } from '../services/token.service';
import Header from './header';
import Menu from './menu';
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../components/globalStyles";
import { lightTheme, darkTheme } from "../components/themes";
import { history } from '../helpers';
import $ from 'jquery';
import SplashScreen from 'pages/SplashScreen/SplashScreen';
import { getUserPagePermission } from '../helpers/commonFunctions'

export const PrivateRoute = ({ component: Component, permission, parentURL, toggleMenu, isMenuOpen, ...rest }) => {

    let userTheme = localStorage.getItem('userTheme');
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        setTheme((userTheme === undefined || userTheme === null) ? 'light' : userTheme);
    }, [userTheme]);

    const themeToggler = () => {

        theme === 'light' ? localStorage.setItem("userTheme", 'dark') : localStorage.setItem("userTheme", 'light');

        theme === 'light' ? setTheme('dark') : setTheme('light');
        $('.mdg-layout-header').toggleClass("darktheme");
    }

    return (
        <Route {...rest} render={props => (
            getAccessToken()
                ? redirectToDefault(props, Component, permission, parentURL, toggleMenu, isMenuOpen, themeToggler, theme)
                : redirectToLogin(props, Component)
        )} />
    )
}

const active_routes = () => {
    const child_routes = {
        dms: {
            planning: ["/querylisting", "/cohortuploadlisting", "/Query", "/cohortuploadfile"],
            administration: ["/userlisting", "/prerunquerylisting", "/audittraillisting", "/salespersonlisting", "/tablemanagementlisting", "/splashscreen", "/licensablefilters", "/role", "/schedulerJobs"]
        },

        access_hcp: {
            downloads: ["/downloadhistorylisting", "/flaggedfiledownloadhistorylisting"]
        }
    }

    document.querySelectorAll(".actvieMenu").forEach(element => {
        element.classList.remove('actvieMenu');
    });
    
    const dashboard = document.querySelectorAll("a.p-panelmenu-header-link")

    //dms
    const planning = document.querySelector("a.p-panelmenu-header-link span.p-menuitem-icon.fa-file-invoice");
    const administration = document.querySelector("a.p-panelmenu-header-link span.p-menuitem-icon.fa-users-cog"); 

    //access_hcp
    const downloads = document.querySelector("a.p-panelmenu-header-link span.p-menuitem-icon.fa-file-download")

    const setChildActiveIcon = (icon, target) => {
        dashboard?.forEach((data, index) => {
            data?.childNodes?.forEach(sibling => {
                sibling?.nextSibling?.classList?.forEach((data) => {
                    if(data === icon){
                        target?.classList?.add('actvieMenu')
                        sibling?.nextSibling?.nextSibling?.classList.add("actvieMenu")
                    }
                })
            })
        })
    }
    
    child_routes.dms.planning.forEach(data => {
        if(window.location.pathname === data){
            setChildActiveIcon("fa-file-invoice", planning)
        }
    })

    child_routes.dms.administration.forEach(data => {
        if(window.location.pathname === data){
            setChildActiveIcon("fa-users-cog", administration)
        }
    })

    child_routes.access_hcp.downloads.forEach(data => {
        if(window.location.pathname === data){
            setChildActiveIcon("fa-file-download", downloads)
        }
    })   
}

function redirectToDefault(props, Component, permission, parentURL, toggleMenu, isMenuOpen, themeToggler, theme) {

    //For the active routes
    window.addEventListener('DOMContentLoaded', (event) => {
        active_routes()
    });

    active_routes()
    
    var havePermission = getUserPagePermission(parentURL, permission);
    if (!havePermission) {
        //console.log("Do not have permission");
        return history.push('/accessdenied');
    } else {
        var returnURl = localStorage.getItem("MDGReturnURL");
        if (returnURl === null || returnURl === undefined || returnURl === '') {
            returnURl = '/querylisting';
        }

        document.body.classList.remove('login-page');
        if (props.match.path === "/login") {
            return history.push(returnURl); //<Redirect to={{ pathname: returnURl, state: { from: props.location } }} />
        } if (props.match.path === "/") {
            return history.push(returnURl); // <Redirect to={{ pathname: returnURl, state: { from: props.location } }} />
        //    <Redirect to={{ pathname: returnURl, state: { from: props.location } }} />
        }
        else {
            return (<>
                <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                    <SplashScreen />
                    <GlobalStyles />
                    <Header isMenuOpen={isMenuOpen} onMenuToggle={toggleMenu} themeToggler={themeToggler}></Header>
                    <section className={isMenuOpen ? '' : 'layout-expand'}>
                        <Menu isMenuOpen={isMenuOpen}
                            onMenuToggle={toggleMenu}></Menu>
                        <div className="mdg-layout">
                            <div className="app-content">
                                <div className="app-content-inner">
                                    <Component {...props} />
                                </div>
                            </div>
                        </div>
                    </section>
                </ThemeProvider>               
            </>            
            )
        }
    }    
}

function redirectToLogin(props, Component) {
    document.body.classList.remove('login-page');

    if (props.match.path === "/login" || props.match.path === "/resetpassword" || props.match.path === "/signup" || props.match.path === '/registrationconfirmation' || props.match.path === '/signupuseremailconfirmation') {
        if (props.location.state && props.location.state.from?.pathname !== '/' ) {
            localStorage.setItem("MDGReturnURL", props.location.state.from?.pathname + props.location.state.from?.search);
            localStorage.setItem("MDGType", "ExternalLink");
            localStorage.setItem("IsDownloadURL", "true");
        } else { localStorage.removeItem("MDGType"); }
        return <Component {...props} />
    }
    else {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
}