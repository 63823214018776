import { dspDistributionListingConstants } from '../constants/liverampdistributionlisting.constants';



const initialState = {
    DspLiverampListingRequest: {},
    DspLiverampListingResponse: {
        lstLiverampDistributionListingResponse: [],
        totalRecords: 0
    },
    error: '',
    success: '',
    showDspDistributionListingLoader: false
};
export function DspLiverampListing(state = initialState, action) {
    switch (action.type) {
        case dspDistributionListingConstants.GETALLDISTRIBUTIONLISTING_REQUEST:
            return {
                ...state,
                showDspDistributionListingLoader: action.showDspDistributionListingLoader
            };
        case dspDistributionListingConstants.GETALLDISTRIBUTIONLISTING_REQUEST_END:
            return {
                ...state,
                showDspDistributionListingLoader: action.showDspDistributionListingLoader
            };
        case dspDistributionListingConstants.GETALLDISTRIBUTIONLISTING_SUCCESS:
            return {
                ...state,
                DspLiverampListingResponse: action.dspLiverampListingResponse

            };
        case dspDistributionListingConstants.GETALLDISTRIBUTIONLISTING_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case dspDistributionListingConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        default:
            return state
    }
}