import { queryListingConstants } from '../constants/querylisting.constants';

const initialState = {
    queryListingRequest: {},
    queryListingResponse: {
        lstQueryListingResponse: [],
        totalRecords: 0
    },
    error: '',
    success: '',
    showQueryListingLoader: false
};

export function PreRunListing(state = initialState, action) {
    switch (action.type) {
        case queryListingConstants.GETALLQUERYLISTING_REQUEST:
            return {
                ...state,
                showQueryListingLoader: action.showQueryListingLoader
            };
        case queryListingConstants.GETALLQUERYLISTING_REQUEST_END:
            return {
                ...state,
                showQueryListingLoader: action.showQueryListingLoader
            };
        case queryListingConstants.GETALLQUERYLISTING_SUCCESS:
            return {
                ...state,
                queryListingResponse: action.queryListingResponse

            };
        case queryListingConstants.GETALLQUERYLISTING_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case queryListingConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        default:
            return state
    }
}