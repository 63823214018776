import { suppressionListingConstants } from '../constants/suppressionlisting.constants';



const initialState = {
    SuppressionListingRequest: {},
    SuppressionListingResponse: {
        lstSupressionListingResponse: [],
        totalRecords: 0
    },
    error: '',
    success: '',
    showSuppressionListingLoader: false
};

export function SuppressionListing(state = initialState, action) {
    switch (action.type) {
        case suppressionListingConstants.GETALLSUPPRESSIONLISTING_REQUEST:
            return {
                ...state,
                showSuppressionListingLoader: action.showSuppressionListingLoader
            };
        case suppressionListingConstants.GETALLSUPPRESSIONLISTING_REQUEST_END:
            return {
                ...state,
                showSuppressionListingLoader: action.showSuppressionListingLoader
            };
        case suppressionListingConstants.GETALLSUPPRESSIONLISTING_SUCCESS:
            return {
                ...state,
                SuppressionListingResponse: action.suppressionListingResponse

            };
        case suppressionListingConstants.GETALLSUPPRESSIONLISTING_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case suppressionListingConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        default:
            return state
    }
}