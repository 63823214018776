import { userConstants } from '../constants';
import { Constant } from '../constants/constants';
import { MessageConstant } from '../constants/message.constant';
import { history } from '../helpers';
import { getData, postData } from '../helpers/api.helper';
import { setAccessToken, setRefreshToken } from '../services/token.service';
import _ from 'lodash';
var CryptoJS = require("crypto-js");

var apiEndPoint = process.env.REACT_APP_API_URL;

export function login(model, isRememberMe) {

    return dispatch => {

        dispatch(LogInRequest(true));
        var defaultURL = "";
        var url = apiEndPoint + "Identity/AuthenticateUser";
        return postData(url, model, 'login').then(
            response => {

                if (response && response.isSuccess) {
                  
                    setAccessToken(response.data.accessToken);
                    setRefreshToken(response.data.refreshToken);
                 
                    var userDetailCipherText = CryptoJS.AES.encrypt(JSON.stringify(response.data), Constant.EncryptionKey).toString();
                    localStorage.setItem("user", JSON.stringify(userDetailCipherText));
                    if (isRememberMe === true) {
                        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(model), Constant.EncryptionKey).toString();
                        var currentDate = new Date();
                        var duedate = new Date(currentDate);
                        duedate.setDate(currentDate.getDate() + 365);
                        // `item` is an object which contains the original value
                        // as well as the time when it's supposed to expire
                        const item = {
                            value: ciphertext,
                            expiry: duedate,
                        }
                        localStorage.setItem(Constant.RememberMeKey, JSON.stringify(item))
                    }                    
                    
                    
                    /* TestToken();*/
                    setTimeout(() => {
                        dispatch(loginSuccess(response.data));
                        dispatch(LogInRequestEnd(false));                        
                    }, 200)
                                
                    var MDGReturnURL = localStorage.getItem("MDGReturnURL");

                    var MDGType = localStorage.getItem("MDGType");

                    if (MDGType !== undefined && response.data.userType !== MDGType && MDGType != "ExternalLink") {
                        MDGReturnURL = '';
                    }

                    setTimeout(() => {

                        if (MDGReturnURL !== undefined && MDGReturnURL !== null && MDGReturnURL !== '') {
                            history.push(MDGReturnURL);
                        } else {

                            if(response.data.userType !== MDGType && response.data.userType.toLowerCase() === 'external') {
                                history.push("/DataSearch");
                            }else{

                               
                                var userProductType = 'DMS';
                                response.data?.roleWisePermission.moduleList.filter(x => x.productType == userProductType ||
                                    (userProductType === "HCPDI" ? x.productType == userProductType : x.productType.toUpperCase() == 'BOTH')).forEach((item, i) => {
                    
                                        item.subModuleList.filter(x => x.isParentMenu === false && (x.subModuleProductType == userProductType
                                            || (userProductType === "HCPDI" ? x.subModuleProductType == userProductType : x.subModuleProductType.toUpperCase() == 'BOTH'))).forEach((subModuleItem, j) => {
                    
                                                if (_.isEmpty(defaultURL)) {
                                                    defaultURL = subModuleItem.url;
                                                }
                                            });
                    
                                        item.subModuleList.filter(x => x.isParentMenu === true &&
                                            (x.subModuleProductType == userProductType
                                                || (userProductType === "HCPDI" ? x.subModuleProductType == userProductType : x.subModuleProductType.toUpperCase() == 'BOTH'))).forEach((subModuleItem, j) => {
                                                    if (_.isEmpty(defaultURL)) {
                                                        defaultURL = subModuleItem.url;
                                                    }
                                                });
                                    });

                                if (_.isEmpty(defaultURL) === false && localStorage.getItem("IsDownloadURL") !== "true") {
                                    // history.push(defaultURL);
                                    window.location.href = defaultURL;
                                }
                                else {
                                    // history.push("/querylisting");   
                                    window.location.href = "/querylisting";
                                    localStorage.removeItem('IsDownloadURL');
                                }                                                         
                            }                            
                        }
                       
                    }, 500)
                }
                else {
                    dispatch(LogInRequestEnd(false));
                    dispatch(loginFailer(response.message));                   
                }
            },
            error =>{                
                dispatch(LogInRequestEnd(false));
                dispatch(loginFailer(MessageConstant.GeneralError))
            }
        );
    };
}

export function ShowLogInLoader() {
    
    return dispatch => {
        dispatch(LogInRequest(true));
    };
}

export function HideLogInLoader() {
    
    return dispatch => {
        dispatch(LogInRequestEnd(false));
    };
}



//export function forgotPassword(email) {

//    return dispatch => {

//        var url = apiEndPoint + "Identity/SendForgotUsernameEmail";
//        return getData(url, email, 'forgotPassword').then(
//            response => {

//                if (response && response.isSuccess) {
//                    var message = "Your password reset link has been sent successfully. Please check your inbox.";
//                    dispatch(forgotPasswordSuccess(message));
//                    history.push("/dashboard");
//                    /* TestToken();*/
//                    setTimeout(() => { TestToken(); }, 61000)

//                }
//                else {
//                    dispatch(forgotPasswordFailer(response.message));
//                }
//            },
//            error => dispatch(loginFailer('please contact admin'))
//        );
//    };
//}

export function TestToken() {
    // return dispatch => {
    var url = apiEndPoint + "Identity/TestToken";

    getData(url, '', 'TestToken').then(
        users => {
            
        },
        error => {
           // var ss = "";
            // (loginFailer('please contact admin'))
        }
    );
    // };
}


export function ClearMessage() {
    return dispatch => {
        dispatch(ClearAllMessage());
    }
}
function loginSuccess(users) { return { type: userConstants.LOGIN_SUCCESS, users } }
function loginFailer(error) { return { type: userConstants.LOGIN_FAILURE, error } }
function ClearAllMessage() { return { type: userConstants.CLEAR_ALL_MESSAGE } }

function LogInRequest(showLogInLoader) { return { type: userConstants.LOGINREQUEST, showLogInLoader } }
function LogInRequestEnd(showLogInLoader) { return { type: userConstants.LOGINREQUESTEND, showLogInLoader } }
