export const MessageConstant = {
    GeneralError: "An error occurred while processing your request. Please contact system admin for additional assistance.",
    ImportMapAtleasetOneFieldError: "Please map a field to proceed with import.",
    ImportFieldAlreadyMapped:"Field already mapped.",
    ImportDetailSuccess: "Import Detail Saved successfully.",
    ImportFileTypeNotAllowed: 'File type not allowed.',
    ImportFileSizeLimit: 'File size greater than 4GB is not allowed.',
    ImportDescriptionRequired: "Please enter description.",
    FileUploadRequired:"Please upload file.",
    SelectFile:"Please select file.",
    TableJoinKeyMapValidation: 'Please map all key in table panel.',
    ImportFieldMappingIsNotProper: "Field Mapping is not correct , please review and map again.",
    OutPutColumnRequired: 'Please select an output column for query execution.',
    DuplicateOutputTitle: 'Duplicate Output Title found.',
    ValidZipRadius: 'Zip Radius should be between 1 to 500. Please enter valid zip radius.',
    DistinctOutPutColumnRequired: "One column must be distinct to output columns. Please add a distinct column.",
    FilterItemRequired: "One filter must be applied to each part. Please add a filter to Part",
    FilterValueRequired: "Value required for following fields.",
    FiterPartDeleteConfirmation: 'This action cannot be undone. Are you sure you want to delete Part?',
    QuerySave: 'Query has been saved successfully.',
    DescriptionRequired:'Please enter description.',
    FileDownloadSuccess:'File downloaded successfully.',
    ExceptionFileDownloadSuccess: 'Exception file downloaded successfully.',
    FileUploadSuccess:'File uploaded successfully.',
    LookupFileDownloadSuccess:'Lookup File downloaded successfully.',
    DummyFileNotAllowed:'Dummy filename is not allowed like DLA-000000. Please rename the file and try to upload again.',
    QueryStoppedSuccess: 'Query Terminated Successfully.',
    TemplateSavedSuccess: 'Template saved successfully.',
    QueryRunSuccess: 'Query has been sent for processing. You will receive an email after the process is completed.',
    QueryUnlockSuccess: 'Query Unlocked successfully.',
    PreRunQuerySaveSucess: 'Pre Run Query saved successfully.',
    PreRunQueryRequired: 'Please select a query to save for pre run.',
    DspFormSuccess: "Request has been submitted successfully for activation.",
    DlaError: 'DLA# is not mapped to Salesforce account. Please review the DLA#.',
    EneterTemplateName: "Please enter template name.",
    MultipleAppendNotAllowed: 'Multiple Append not allowed.',
    OneBhehaviroalCodeRequired: 'Please add at least one Behavioral Code or Marketed Product Name or Molecule Name field in filter.',
    DecileCalculationTypeRequired: 'Please select Decile calculation type.',
    DecileCountRequired: 'Please add Decile Count in filter or output section to calculate decile.',

}