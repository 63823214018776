import { deepIntentListingConstants } from '../constants/deepintentlisting.constants';


const initialState = {
  deepIntentListingRequest: {},
  deepIntentListingResponse: {
    lstDeepIntentListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showDeepIntentListingLoader: false
};

export function deepIntentListing(state = initialState, action) {
  switch (action.type) {
    case deepIntentListingConstants.GETALLDEEPINTENTLISTING_REQUEST:
      return {
        ...state,
        showDeepIntentListingLoader: action.showDeepIntentListingLoader
      };
    case deepIntentListingConstants.GETALLDEEPINTENTLISTING_REQUEST_END:
      return {
        ...state,
        showDeepIntentListingLoader: action.showDeepIntentListingLoader
      };
    case deepIntentListingConstants.GETALLDEEPINTENTLISTING_SUCCESS:
      return {
        ...state,
        deepIntentListingResponse: action.deepIntentListingResponse

      };
    case deepIntentListingConstants.GETALLDEEPINTENTLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case deepIntentListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}