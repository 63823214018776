import axios from "axios";
import { toast } from "react-toastify";
import { MessageConstant } from "../constants/message.constant";
import { getAccessToken, removeAccessToken, removeRefreshToken } from "../services/token.service";
import { history } from './history';
var apiEndPoint = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (request) {
    document.querySelectorAll(".extraLoaderClass").forEach((el, index) => {
        el.classList.remove('networkLoaderClass');
       // el.setAttribute('style','display:block');
    });
    return request;
  }, function (error) {
    return Promise.reject(error);
  });


axios.interceptors.response.use((response) => {
    //&& response.data.data=="<html><head><title>504 Gateway Time-out</title></head><body><center><h1>504 Gateway Time-out</h1></center></body></html>"
    //if (response.data && response.data.statusCode && response.data.statusCode == 400 ) {

    //    const originalRequest = response.config;
    //    originalRequest._retry = true;
    //    return axios(originalRequest);

    //}
    return response
},
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 409 && error.response.data.message === "You been logged in other Browser, if not you then please Contact Adminitrator") {
            // console.log('409');
            //originalRequest.catch(error => {
            //    console.log('originalRequest.catch', originalRequest)
            //});
            ////Promise.catch(error => { });

            originalRequest._retry = true;
            //originalRequest.caller.catch(error => {
            //    console.log('originalRequest.catch', originalRequest)
            //    debugger;
            //})
            //axios.Cancel = true;
            toast.error("You been logged in other Browser, if not you then please Contact Adminitrator");
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('preRunQueryListingRequest');
            localStorage.removeItem('queryListingRequest');
            localStorage.removeItem('importListingRequest');
            localStorage.removeItem('suppressionListingRequest');
            localStorage.removeItem('userListingRequest');
            localStorage.removeItem('cohortUploadListingRequest');
            localStorage.removeItem('preRunQueryListingRequest')
            localStorage.removeItem('IsDownloadURL');           
            localStorage.removeItem('Menu');
            sessionStorage.removeItem("localMenu");
            localStorage.removeItem('userTheme');
            //localStorage.removeItem('user');
            setTimeout(() => { history.push("/login"); }, 3200);
            return new Promise(() => { });

        }
        else if (error.response.status === 401 && error.response.data === 'Permission Denied') {
           toast.error("You can not perform this action.");
            document.querySelectorAll(".box-loader").forEach((el, index) => {
                el.classList.add('networkLoaderClass');
                // el.setAttribute('style','display:none');
            });
            return new Promise(() => { });
        }
        else if (error.response && error.response.status === 401 && !originalRequest._retry) {
            // console.log('401');
            originalRequest._retry = true;
            var refreshTokenRequest = { "refreshToken": localStorage.getItem('refreshToken'), "ipAddress": "" };
            return axios.post(apiEndPoint + 'Identity/RefreshToken', refreshTokenRequest)
                .then(res => {
                    if (res.status === 201 || res.status === 200) {
                        if (res.data.statusCode === 400) {
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('preRunQueryListingRequest');
                            localStorage.removeItem('queryListingRequest');
                            localStorage.removeItem('importListingRequest');
                            localStorage.removeItem('suppressionListingRequest');
                            localStorage.removeItem('userListingRequest');
                            localStorage.removeItem('cohortUploadListingRequest');
                            localStorage.removeItem('preRunQueryListingRequest')
                            localStorage.removeItem('IsDownloadURL');                      
                            localStorage.removeItem('Menu');
                            sessionStorage.removeItem("localMenu");
                            localStorage.removeItem('userTheme');
                            //localStorage.removeItem('user');

                            Promise.reject(error);
                            setTimeout(() => { history.push("/login"); }, 800);

                        }
                        else {
                            localStorage.setItem('accessToken', res.data.data.accessToken);
                            localStorage.setItem('refreshToken', res.data.data.refreshToken);

                            //axios.defaults.headers.common['Authorization'] = localStorage.getItem('user');
                            var accessToken = localStorage.getItem('accessToken');
                            var headertoken = { 'content-type': 'application/json' };
                            if (accessToken !== '') {
                                headertoken = { 'Authorization': accessToken, 'content-type': 'application/json' }
                            }
                            originalRequest.headers = headertoken;

                            return axios(originalRequest);
                        }
                    }
                    if (res.status === 400 || res.status === 400) {
                        removeAccessToken();
                        removeRefreshToken();
                        localStorage.removeItem('preRunQueryListingRequest');
                        localStorage.removeItem('queryListingRequest');
                        localStorage.removeItem('importListingRequest');
                        localStorage.removeItem('suppressionListingRequest');
                        localStorage.removeItem('userListingRequest');
                        localStorage.removeItem('cohortUploadListingRequest');
                        localStorage.removeItem('preRunQueryListingRequest');
                        localStorage.removeItem('IsDownloadURL');                      
                        localStorage.removeItem('Menu');
                        sessionStorage.removeItem("localMenu");
                        localStorage.removeItem('userTheme');
                        //localStorage.removeItem('user');
                        history.push("/login");
                    }
                    if (res.status === 404) {
                        history.push("/pagenotfound")
                    }
                    if (res.status === 500) {
                        toast.error(getRequestGenenralError());
                    }

                })
        }
        // toast.error(getRequestGenenralError());
        // return Error object with Promise
        return Promise.reject(error);
    });



export function getData(url, data, actionType) {
    const dataForGet = data ? ('?' + Object.keys(data).map(k => k + '=' + data[k]).join('&')) : '';
    url = url + dataForGet;
    var accessToken = getAccessToken();

    var headertoken = { 'content-type': 'application/json' };
    if (accessToken !== '') {
        headertoken = { 'Authorization': accessToken, 'content-type': 'application/json' }
    }
    // axiosInstanceWithBaseURL.headers = headertoken;
    //return axiosInstanceWithBaseURL.get(url).then((response) => {
    return axios({
        method: 'GET',
        headers: headertoken,
        url: url,
       // timeout: 1000,
    }).then((response) => {
        return response.data
    }).catch((error) => {
        console.log("Error from " + actionType + " method");
        console.log("error", error);
        return Promise.reject(getRequestGenenralError());
    });

}
export function postData(url, data, actionType) {
    let endPointUrl = url;
    var accessToken = getAccessToken();

    var headertoken = { 'content-type': 'application/json' };
    if (accessToken !== '') {
        headertoken = { 'Authorization': accessToken, 'content-type': 'application/json' }
    }
    return axios({
        method: 'post',
        headers: headertoken,
        url: endPointUrl,
        //timeout: 1000,
        data: data
    }).then((response) => {
        return response.data
    }).catch((error) => {
        console.log("Error from " + actionType + " method");
        console.log("error", error);
        return Promise.reject(getRequestGenenralError);
    });

}

export async function postDataAsync(url, data, actionType) {
    let endPointUrl = url;
    var accessToken = getAccessToken();
    var headertoken = { 'content-type': 'application/json' };
    if (accessToken !== '') {
        headertoken = { 'Authorization': accessToken, 'content-type': 'application/json' }
    }

    return await axios({
        method: 'post',
        headers: headertoken,
       // timeout: 1000,
        url: endPointUrl,
        data: data
    })

}

export async function postFileDataAsync(url, files, onUploadProgress) {
    var accessToken = getAccessToken();
    var headertoken = { 'content-type': 'application/json' };
    if (accessToken !== '') {
        headertoken = { 'Authorization': accessToken, 'content-type': 'application/json' }
    }
    return await axios.post(url, files, {
        headers: headertoken,
        onUploadProgress
    });
}




function getRequestGenenralError() {
    return MessageConstant.GeneralError;
}

