import { zerobounceListingConstants } from '../constants/zerobouncelisting.constants';


const initialState = {
    zerobounceListingRequest: {},
    zerobounceListingResponse: {
    lstZerobounceListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showZerobounceListingLoader: false
};

export function zerobounceListing(state = initialState, action) {
  switch (action.type) {
    case zerobounceListingConstants.GETALLZEROBOUNCELISTING_REQUEST:
      return {
        ...state,
        showZerobounceListingLoader: action.showZerobounceListingLoader
      };
    case zerobounceListingConstants.GETALLZEROBOUNCELISTING_REQUEST_END:
      return {
        ...state,
        showZerobounceListingLoader: action.showZerobounceListingLoader
      };
    case zerobounceListingConstants.GETALLZEROBOUNCELISTING_SUCCESS:
      return {
        ...state,
        zerobounceListingResponse: action.zerobounceListingResponse

      };
    case zerobounceListingConstants.GETALLZEROBOUNCELISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case zerobounceListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}