import { importListingConstants } from '../constants';



const initialState = {
  importListingRequest: {},
  importListingResponse: {
    lstImportListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showImportListingLoader: false
};

export function importListing(state = initialState, action) {
  switch (action.type) {
    case importListingConstants.GETALLIMPORTLISTING_REQUEST:
      return {
        ...state,
        showImportListingLoader: action.showImportListingLoader
      };
    case importListingConstants.GETALLIMPORTLISTING_REQUEST_END:
      return {
        ...state,
        showImportListingLoader: action.showImportListingLoader
      };
    case importListingConstants.GETALLIMPORTLISTING_SUCCESS:
      return {
        ...state,
        importListingResponse: action.importListingResponse

      };
    case importListingConstants.GETALLIMPORTLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case importListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}