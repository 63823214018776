import { combineReducers } from 'redux';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { importListing } from './importlisting.reducer';
import { SuppressionListing } from './suppressionlisting.reducer';
import { queryListing } from './querylisting.reducer';
import { queryDetail } from './querydetail.reducer';
import { query } from './query.reducer';
import { userListing } from './userlisting.reducer';
import { cohortUploadListing } from './cohortuploadlisting.reducer';
import { PreRunListing } from './prerunquerlisting.reducer';
import { audittrailListing } from './audittrail.reducer';
import { tableManagementListing } from './tablemanagementlisting.reducer';
import { downloadHistoryListing } from './downloadhistorylisting.reducer';
import { splashScreens } from './splashscreen.reducer';
import { flaggedFileDownloadHistoryListing } from './flaggedfiledownloadhistorylisting.reducer';
import { rolemanagement } from './rolemanagement.reducer';
import { salesPersonListing } from './salesperson.reducer';
import { headers } from './header.reducer';
import { DspLiverampListing } from './liverampdistributionlisting.reducer';
import { DspRtaListing } from './rtadistributionlisting.reducer';
import {identityPartnerOnboardingListing} from './identitypartneronboardinglisting.reducer';
import {deepIntentListing} from './deepintentlisting.reducer';
import {zerobounceListing} from './zerobouncelisting.reducer';
import {criticalImpactLogListing} from './criticalimpactloglisting.reducer';


const rootReducer = combineReducers({ users, alert, importListing, SuppressionListing, queryListing, queryDetail, query, 
    userListing, cohortUploadListing, PreRunListing, audittrailListing, tableManagementListing, downloadHistoryListing, 
    splashScreens, flaggedFileDownloadHistoryListing, rolemanagement, salesPersonListing, headers, DspLiverampListing, DspRtaListing, 
    identityPartnerOnboardingListing,deepIntentListing,zerobounceListing,criticalImpactLogListing });

export default rootReducer;