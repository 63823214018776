import { identityPartnerOnboardingConstants } from '../constants/identitypartneronboardinglisting.constants';


const initialState = {
  identityPartnerOnboardingListingRequest: {},
  identityPartnerOnboardingListingResponse: {
    identityPartnerOnboardingListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showIdentityPartnerOnboardingListingLoader: false
};

export function identityPartnerOnboardingListing(state = initialState, action) {
  switch (action.type) {
    case identityPartnerOnboardingConstants.GETALLIDENTITYPARTNERONBOARDINGLISTING_REQUEST:
      return {
        ...state,
        showIdentityPartnerOnboardingListingLoader: action.showIdentityPartnerOnboardingListingLoader
      };
    case identityPartnerOnboardingConstants.GETALLIDENTITYPARTNERONBOARDINGLISTING_REQUEST_END:
      return {
        ...state,
        showIdentityPartnerOnboardingListingLoader: action.showIdentityPartnerOnboardingListingLoader
      };
    case identityPartnerOnboardingConstants.GETALLIDENTITYPARTNERONBOARDINGLISTING_SUCCESS:
      return {
        ...state,
        identityPartnerOnboardingListingResponse: action.identityPartnerOnboardingListingResponse

      };
    case identityPartnerOnboardingConstants.GETALLIDENTITYPARTNERONBOARDINGLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case identityPartnerOnboardingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}